import { render, staticRenderFns } from "./AppSettings.vue?vue&type=template&id=111a5b03&scoped=true"
import script from "./AppSettings.vue?vue&type=script&lang=ts"
export * from "./AppSettings.vue?vue&type=script&lang=ts"
import style0 from "./AppSettings.vue?vue&type=style&index=0&id=111a5b03&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "111a5b03",
  null
  
)

export default component.exports