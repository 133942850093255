import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'

export default {
  computed: {
    ...trialSettingsMapGetters(),
    treatmentArmDisplayName() {
      return this.trialTreatmentName(this.newPatientInfo.treatmentArm)
    },
    showTreatmentArm() {
      return this.trialTreatmentArms?.length > 1
    },
    showDosingRegimens() {
      return this.trialDosingRegimens?.length > 1
    },
  },
}
