<template>
  <v-card class="rounded-lg fill-height">
    <v-card-text class="pa-8">
      <v-row no-gutters>
        <v-col sm="4"><slot name="icon" /></v-col>
        <v-col sm="8">
          <h4 class="d-flex align-center justify-space-between">
            <slot name="title" />
          </h4>
          <p class="mt-4 pr-12 pr-md-0">
            <slot />
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset="4" class="d-flex align-center justify-space-between">
          <slot name="chip">
            <v-spacer />
          </slot>

          <slot name="action" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AppSettingsCard',
}
</script>
