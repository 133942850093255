import appState from '@/constants/appState'
import patientConfigurationAppState from '@/constants/patientConfigurationAppState'

export function updatePatientConfigurationAppState({
  state,
  action,
  patientNumber,
}) {
  switch (state) {
    case appState.ACTIVATED:
      action({
        patientNumber: patientNumber,
        appState: patientConfigurationAppState.COMPLETED,
      })
      break
  }
}
