<template>
  <AppSettings :patient-no="patientNo" />
</template>

<script>
import AppSettings from '@/components/patient/app/AppSettings'
export default {
  components: { AppSettings },
  props: { patientNo: { type: String, required: true } },
}
</script>
