<template functional>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="140" height="140" rx="70" fill="#FDFCFC" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M91.2121 27.6604H48.7879C45.9762 27.6604 43.697 29.8905 43.697 32.6415V107.358C43.697 110.109 45.9762 112.34 48.7879 112.34H91.2121C94.0238 112.34 96.303 110.109 96.303 107.358V32.6415C96.303 29.8905 94.0238 27.6604 91.2121 27.6604ZM48.7879 26C45.039 26 42 28.9735 42 32.6415V107.358C42 111.026 45.039 114 48.7879 114H91.2121C94.961 114 98 111.026 98 107.358V32.6415C98 28.9735 94.961 26 91.2121 26H48.7879Z"
      fill="#CCCCCC"
    />
    <path
      d="M71.9465 105.783C71.9465 106.813 71.087 107.669 69.9999 107.669C68.9129 107.669 68.0533 106.813 68.0533 105.783C68.0533 104.753 68.9129 103.897 69.9999 103.897C71.087 103.897 71.9465 104.753 71.9465 105.783Z"
      fill="#CCCCCC"
      stroke="#CCCCCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M65.0327 34.1686C65.0327 33.7117 65.4126 33.3413 65.8812 33.3413H79.457C79.9256 33.3413 80.3054 33.7117 80.3054 34.1686C80.3054 34.6255 79.9256 34.9959 79.457 34.9959H65.8812C65.4126 34.9959 65.0327 34.6255 65.0327 34.1686Z"
      fill="#CCCCCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.3357 34.1688C63.3357 34.6257 62.9558 34.9961 62.4872 34.9961L60.7902 34.9961C60.3216 34.9961 59.9418 34.6257 59.9418 34.1688C59.9418 33.7119 60.3216 33.3415 60.7902 33.3415L62.4872 33.3415C62.9558 33.3415 63.3357 33.7119 63.3357 34.1688Z"
      fill="#CCCCCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M68.5049 60.8555H70.9515V72.7832H68.5049V60.8555ZM75.123 65.1375L76.8601 63.4438C79.2333 65.4119 80.7379 68.3222 80.7379 71.5904C80.7379 77.5185 75.808 82.3254 69.7282 82.3254C63.6484 82.3254 58.7185 77.5185 58.7185 71.5904C58.7185 68.3222 60.2232 65.4119 62.5964 63.4438L64.3212 65.1256C62.4006 66.6523 61.1651 68.9782 61.1651 71.5904C61.1651 76.2064 64.994 79.9398 69.7282 79.9398C74.4624 79.9398 78.2913 76.2064 78.2913 71.5904C78.2913 68.9782 77.0558 66.6523 75.123 65.1375Z"
      fill="#B9E6B4"
    />
    <line x1="97.4563" y1="98.5962" x2="42" y2="98.5962" stroke="#CCCCCC" />
    <line x1="97.4563" y1="41.3433" x2="42" y2="41.3433" stroke="#CCCCCC" />
  </svg>
</template>

<script>
export default {
  name: 'ImgActivateApp',
}
</script>
