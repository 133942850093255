
import Vue from 'vue'
import service from '@/services/app-service'
import loadingState from '@/constants/loadingState'
import appState from '@/constants/appState'
import ImgDialpad from '@/components/images/ImgDialpad.vue'
import ImgDeactivateApp from '@/components/images/ImgDeactivateApp.vue'
import AppSettingsCard from './_AppSettingsCard.vue'
import {
  selectedPatientMapGetters,
  selectedPatientMapActions,
} from '@/store/modules/selectedPatientModule'
import ImgResumeActivation from '@/components/images/ImgResumeActivation.vue'
import ImgActivateApp from '@/components/images/ImgActivateApp.vue'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import { updatePatientConfigurationAppState } from '@/utils/updateAppState'
import patientConfigurationAppState from '@/constants/patientConfigurationAppState'

export default Vue.extend({
  name: 'AppSettings',
  mixins: [permissionsGettersMixin, trialSettingsGettersMixin],
  components: {
    ImgActivateApp,
    ImgResumeActivation,
    AppSettingsCard,
    ImgDeactivateApp,
    ImgDialpad,
    ActivateAppSteps: () =>
      import('@/components/patient/app/_ActivateAppSteps.vue'),
    ResumeAppSteps: () =>
      import('@/components/patient/app/_ResumeAppSteps.vue'),
    DeactivateAppSteps: () =>
      import('@/components/patient/app/_DeactivateAppSteps.vue'),
    ResetPinCodeSteps: () =>
      import('@/components/patient/app/_ResetPinCodeSteps.vue'),
    CurrentAppSettingsCard: () =>
      import('@/components/patient/app/settings/CurrentAppSettingsCard.vue'),
  },
  props: { patientNo: { type: String, required: true } },
  data() {
    return {
      loadingState: loadingState.INITIAL,
      appInfoLoadingState: loadingState.INITIAL,
      state: appState.UNKNOWN,
      appInfo: {},
      appState,
      showDialog: false,
      dialogRevision: 0,
      pinCodeChanged: false,
      showAppChanged: false,
    }
  },
  computed: {
    ...selectedPatientMapGetters(),

    appPinCodeEnabled() {
      return this.trialFeatureFlags(this.patientNo).appPinCodeEnabled
    },
    isLoading() {
      return this.loadingState === loadingState.LOADING
    },
    showLoading() {
      return this.loadingState === loadingState.INITIAL || this.isLoading
    },
    isDeviceActivated() {
      return this.state === appState.ACTIVATED
    },
    showAppInfoLoading() {
      return (
        this.appInfoLoadingState === loadingState.INITIAL ||
        this.appInfoLoadingState === loadingState.LOADING
      )
    },
    patientAppState() {
      return this.selectedPatientConfiguration(this.patientNo)?.appState || ''
    },
  },
  created() {
    this.loadAppState()
  },
  methods: {
    ...selectedPatientMapActions(),

    loadAppInfo() {
      this.appInfoLoadingState = loadingState.LOADING
      service
        .getAppInfo(this.patientNo)
        .then((response: any) => {
          this.appInfoLoadingState = loadingState.LOAD_SUCCEEDED
          this.appInfo = response
        })
        .catch(error => {
          this.appInfoLoadingState = loadingState.LOAD_ERRORED

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    loadAppState() {
      this.loadingState = loadingState.LOADING
      service
        .getAppState(this.patientNo)
        .then((response: any) => {
          updatePatientConfigurationAppState({
            state: response.state,
            action: this.selectedPatientUpdateAppState,
            patientNumber: this.patientNo,
          })

          this.loadingState = loadingState.LOAD_SUCCEEDED
          this.state = response.state
          if (this.state === appState.ACTIVATED) {
            this.loadAppInfo()
          }
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
    startActivation() {
      this.dialogRevision += 1
      this.showDialog = true
    },
    resumeActivation() {
      this.dialogRevision += 1
      this.showDialog = true
      this.$nextTick(() => {
        this.$refs.resumeAppSteps.callApiGetPendingApp()
      })
    },
    setPinCodeChanged() {
      this.pinCodeChanged = true
    },
    appDeactivated() {
      this.showDialog = false
      this.loadAppState()
      this.appInfo = {}
      this.showAppChanged = true
    },
    closeDialog() {
      this.showDialog = false
      this.loadAppState()
    },
  },
  watch: {
    patientAppState(newPatientAppState, oldPatientAppState) {
      if (newPatientAppState !== oldPatientAppState) {
        if (newPatientAppState === patientConfigurationAppState.COMPLETED) {
          this.closeDialog()
        }
      }
    },
  },
})
