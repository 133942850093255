import Vue from 'vue'
import store from '@/store'
import { selectedPatientModule } from '@/store/modules/selectedPatientModule'
import appState from '@/constants/appState'
import patientConfigurationAppState from '@/constants/patientConfigurationAppState.js'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'

export default {
  createApp(data) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .post('/web/patient/app', data)
        .then(async response => {
          let setAppState

          switch (response.data.state) {
            case appState.UNKNOWN:
            case appState.ACTIVATE_RESUMABLE:
              setAppState = patientConfigurationAppState.PENDING
              break
            case appState.ACTIVATE_POSSIBLE:
            case appState.ACTIVATE_BLOCKED:
              setAppState = patientConfigurationAppState.BLOCKED
              break
            case appState.ACTIVATED:
              setAppState = patientConfigurationAppState.COMPLETED
              break
          }

          await store.dispatch(
            `${selectedPatientModule}/selectedPatientUpdateAppState`,
            { appState: setAppState, patientNumber: data.patientNumber }
          )
          resolve(response.data)
        })
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('createApp error', error, { data })
          reject(error)
        })
    })
  },
  deleteApp(data) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .delete('/web/patient/app', { data })
        .then(async response => {
          await store.dispatch(
            `${selectedPatientModule}/loadPatientConfigurationRequested`,
            data.patientNumber
          )
          resolve(response.data)
        })
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('deleteApp error', error, { data })
          reject(error)
        })
    })
  },
  getAppInfo(patientNo) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get(`/web/patient/app/info?patientNumber=${patientNo}`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getAppInfo error', error, { patientNo })
          reject(error)
        })
    })
  },
  getAppState(patientNo) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get(`/web/patient/app?patientNumber=${patientNo}`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getAppState error', error, { patientNo })
          reject(error)
        })
    })
  },
  getPendingApp(patientNo) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .get(`/web/patient/app/pending?patientNumber=${patientNo}`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getPendingApp error', error, { patientNo })
          reject(error)
        })
    })
  },
  updateAppSettings(request) {
    return new Promise((resolve, reject) => {
      Vue.$http
        .put('/web/patient/app', request)
        .then(async response => {
          await store.dispatch(
            `${selectedPatientModule}/loadPatientConfigurationRequested`,
            request.patientNumber
          )

          resolve(response.data)
        })
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('updateAppSettings error', error, request)
          reject(error)
        })
    })
  },
  resetPinCode(patientNo) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .put('/web/patient/app/resetPinCode', {
          patientNumber: patientNo,
          reason: 'HCP request reset pin code for patients app',
          resetFlag: true,
          currentClientTime,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('resetPinCode error', error, {
            patientNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
}
