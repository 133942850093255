<template functional>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="140" height="140" rx="70" fill="#FDFCFC" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M91.2121 27.6604H48.7879C45.9762 27.6604 43.697 29.8905 43.697 32.6415V107.358C43.697 110.109 45.9762 112.34 48.7879 112.34H91.2121C94.0238 112.34 96.303 110.109 96.303 107.358V32.6415C96.303 29.8905 94.0238 27.6604 91.2121 27.6604ZM48.7879 26C45.039 26 42 28.9735 42 32.6415V107.358C42 111.026 45.039 114 48.7879 114H91.2121C94.961 114 98 111.026 98 107.358V32.6415C98 28.9735 94.961 26 91.2121 26H48.7879Z"
      fill="#CCCCCC"
    />
    <path
      d="M71.9465 105.783C71.9465 106.813 71.087 107.669 69.9999 107.669C68.9129 107.669 68.0533 106.813 68.0533 105.783C68.0533 104.754 68.9129 103.898 69.9999 103.898C71.087 103.898 71.9465 104.754 71.9465 105.783Z"
      fill="#CCCCCC"
      stroke="#CCCCCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M65.0326 34.1686C65.0326 33.7117 65.4125 33.3413 65.8811 33.3413H79.4568C79.9254 33.3413 80.3053 33.7117 80.3053 34.1686C80.3053 34.6255 79.9254 34.9959 79.4568 34.9959H65.8811C65.4125 34.9959 65.0326 34.6255 65.0326 34.1686Z"
      fill="#CCCCCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.3356 34.1685C63.3356 34.6255 62.9557 34.9958 62.4871 34.9958L60.7901 34.9958C60.3215 34.9958 59.9416 34.6255 59.9416 34.1685C59.9416 33.7116 60.3215 33.3412 60.7901 33.3412L62.4871 33.3412C62.9557 33.3412 63.3356 33.7116 63.3356 34.1685Z"
      fill="#CCCCCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M69.7282 59.6626C62.9756 59.6626 57.4952 65.0062 57.4952 71.5903C57.4952 78.1744 62.9756 83.518 69.7282 83.518C76.4809 83.518 81.9613 78.1744 81.9613 71.5903C81.9613 65.0062 76.4809 59.6626 69.7282 59.6626ZM59.9418 71.5903C59.9418 76.8504 64.3335 81.1325 69.7282 81.1325C75.123 81.1325 79.5147 76.8504 79.5147 71.5903C79.5147 66.3302 75.123 62.0481 69.7282 62.0481C64.3335 62.0481 59.9418 66.3302 59.9418 71.5903ZM63.6117 70.3975V72.7831H75.8448V70.3975H63.6117Z"
      fill="#EB7765"
    />
    <line x1="97.4563" y1="98.5964" x2="42" y2="98.5964" stroke="#CCCCCC" />
    <line x1="97.4563" y1="41.3433" x2="42" y2="41.3433" stroke="#CCCCCC" />
  </svg>
</template>

<script>
export default {
  name: 'ImgDeactivateApp',
}
</script>
