<template functional>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="140" height="140" rx="70" fill="#FDFCFC" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M91.2121 27.6604H48.7879C45.9762 27.6604 43.697 29.8905 43.697 32.6415V107.358C43.697 110.109 45.9762 112.34 48.7879 112.34H91.2121C94.0238 112.34 96.303 110.109 96.303 107.358V32.6415C96.303 29.8905 94.0238 27.6604 91.2121 27.6604ZM48.7879 26C45.039 26 42 28.9735 42 32.6415V107.358C42 111.026 45.039 114 48.7879 114H91.2121C94.961 114 98 111.026 98 107.358V32.6415C98 28.9735 94.961 26 91.2121 26H48.7879Z"
      fill="#CCCCCC"
    />
    <path
      d="M71.9465 105.783C71.9465 106.813 71.087 107.669 69.9999 107.669C68.9129 107.669 68.0533 106.813 68.0533 105.783C68.0533 104.753 68.9129 103.897 69.9999 103.897C71.087 103.897 71.9465 104.753 71.9465 105.783Z"
      fill="#CCCCCC"
      stroke="#CCCCCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M65.0327 34.1686C65.0327 33.7117 65.4126 33.3413 65.8812 33.3413H79.457C79.9256 33.3413 80.3054 33.7117 80.3054 34.1686C80.3054 34.6255 79.9256 34.9959 79.457 34.9959H65.8812C65.4126 34.9959 65.0327 34.6255 65.0327 34.1686Z"
      fill="#CCCCCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.3357 34.1688C63.3357 34.6257 62.9558 34.9961 62.4872 34.9961L60.7902 34.9961C60.3216 34.9961 59.9418 34.6257 59.9418 34.1688C59.9418 33.7119 60.3216 33.3415 60.7902 33.3415L62.4872 33.3415C62.9558 33.3415 63.3357 33.7119 63.3357 34.1688Z"
      fill="#CCCCCC"
    />
    <path
      d="M76.6337 64.8514C74.8599 63.1218 72.4255 62.0483 69.722 62.0483C64.3151 62.0483 59.9479 66.3185 59.9479 71.5905C59.9479 76.8626 64.3151 81.1327 69.722 81.1327C74.285 81.1327 78.0894 78.0911 79.1782 73.976H76.6337C75.6306 76.7552 72.9149 78.7471 69.722 78.7471C65.6729 78.7471 62.3822 75.5386 62.3822 71.5905C62.3822 67.6424 65.6729 64.4339 69.722 64.4339C71.7527 64.4339 73.5632 65.2569 74.8844 66.557L70.9454 70.3977H79.5085V62.0483L76.6337 64.8514Z"
      fill="#F7DD99"
    />
    <line x1="97.4563" y1="98.5962" x2="42" y2="98.5962" stroke="#CCCCCC" />
    <line x1="97.4563" y1="41.3433" x2="42" y2="41.3433" stroke="#CCCCCC" />
  </svg>
</template>

<script>
export default {
  name: 'ImgResumeActivation',
}
</script>
